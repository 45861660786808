exports.components = {
  "component---src-pages-anmeldung-js": () => import("./../../../src/pages/anmeldung.js" /* webpackChunkName: "component---src-pages-anmeldung-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-spenden-index-js": () => import("./../../../src/pages/spenden/index.js" /* webpackChunkName: "component---src-pages-spenden-index-js" */),
  "component---src-pages-spenden-paypal-cancel-js": () => import("./../../../src/pages/spenden/paypal_cancel.js" /* webpackChunkName: "component---src-pages-spenden-paypal-cancel-js" */),
  "component---src-pages-spenden-paypal-completed-js": () => import("./../../../src/pages/spenden/paypal_completed.js" /* webpackChunkName: "component---src-pages-spenden-paypal-completed-js" */)
}

