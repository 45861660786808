// global imports and scripts

import "./src/style-base/global.scss"

import * as Sentry from "@sentry/browser"
import ReactGA from "react-ga"

// error monitoring
Sentry.init({ dsn: process.env.SENTRY_DSN })

// analytics
ReactGA.initialize(true, {
  // true replaces the trackingId, already set by gatsby plugin
  testMode: process.env.ACTIVE_ENV === "development",
  debug: process.env.ACTIVE_ENV !== "production",
  // ga comes from gatsby plugin
  useExistingGa: true,
})
